import Box from "../../components/Box"
import Container from "../../components/UI/Container"
import Fade from "react-reveal/Fade"
import Image from "gatsby-image"
import MailIcon from "../../common/assets/image/elegant/teamMembers/mail.svg"
import PageTitle from "../../containers/PageTitle"
import PhoneIcon from "../../common/assets/image/elegant/teamMembers/phone-icon.svg"
import React from "react"
import SectionWrapper from "./teamMemberIntro.style"
import Text from "../../components/Text"

const TeamMemberIntro = ({
  title,
  subtitle,
  email,
  mainImage,
  phone,
  companyPosition,
}) => {
  return (
    <SectionWrapper>
      <Container width="1800px">
        <Fade up delay={100}>
          <PageTitle title={title} subtitle={subtitle} />
          <Text content={companyPosition} className="company_position" />
          <Box className="main_image">
            <Image fluid={mainImage.fluid} alt={title} />
          </Box>
        </Fade>
      </Container>
      <Fade up delay={100}>
        <Container width="918px" className="contact_container">
          <div className="contact">
            <img src={PhoneIcon} alt="phone" />
            <a href={`tel:${phone}`} target="_blank">
              <Text content={phone} />
            </a>
          </div>
          <div className="contact">
            <img src={MailIcon} alt="phone" />
            <a href={`mailto:${email}`} target="_blank">
              <Text content={email} />
            </a>
          </div>
        </Container>
      </Fade>
    </SectionWrapper>
  )
}

export default TeamMemberIntro
