import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const SectionWrapper = styled.section`
  position: relative;

  .company_position {
    font-family: "bariol-regular";
    display: flex;
    justify-content: center;
    margin-top: 0px;
    letter-spacing: 1px;
    margin-bottom: 80px;

    @media only screen and (max-width: 480px) {
      margin-bottom: 56px;
    }
  }

  .contact_container {
    display: flex;
    margin-top: 40px;
    @media only screen and (max-width: 580px) {
      flex-direction: column;
      margin-top: 24px;
    }
  }

  .contact {
    margin-right: 32px;
    display: flex;
    align-items: center;
    @media only screen and (max-width: 580px) {
      margin-bottom: 16px;
    }

    img {
      margin-right: 12px;
    }

    p {
      margin-bottom: 0px;
    }
  }
`

export default SectionWrapper
