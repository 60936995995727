import { graphql, useStaticQuery } from "gatsby"

import AboutPagesContent from "../containers/AboutPagesContent"
import BreadCrumbsAbout from "../containers/BreadcrumbsAbout"
import ChangeYourLife from "../containers/ChangeYourLife"
import { ContentWrapper } from "../containers/elegant.style"
import Layout from "../containers/Layout"
import React from "react"
import SEO from "../components/SEO"
import TeamMemberIntro from "../containers/TeamMemberIntro"

const TeamMemberTemplate = ({ data }) => {
  const {
    seoDetails,
    companyPosition,
    content,
    email,
    mainImage,
    name,
    phone,
  } = data.contentfulTeamMember

  return (
    <>
      <SEO
        title={seoDetails.metaTitle}
        shortDesc={seoDetails.metaShortDescription}
        description={seoDetails.metaDescription}
      />
      <Layout>
        <ContentWrapper>
          <BreadCrumbsAbout currentPage={name} meetTheTeam />
          <TeamMemberIntro
            title={name}
            subtitle="Meet The Team"
            phone={phone}
            email={email}
            mainImage={mainImage}
            companyPosition={companyPosition}
          />
          <AboutPagesContent content={content} />
          <ChangeYourLife
            whiteBG
            title="Ready to change your life?"
            text="Begin your journey with us today. With Elegant Beauty clinics across the Bournemouth, Poole and Southampton area, we invite you to visit us for a free consultation at your convenience."
          />
        </ContentWrapper>
      </Layout>
    </>
  )
}

export default TeamMemberTemplate

export const teamMembersQuery = graphql`
  query teamMembersBySlug($slug: String!) {
    contentfulTeamMember(slug: { eq: $slug }) {
      email
      phone
      slug
      name
      seoDetails {
        metaDescription
        metaShortDescription
        metaTitle
      }
      content {
        json
      }
      companyPosition
      mainImage {
        fluid(quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`
